import React, { useEffect } from "react";

const WatchOurAds = () => {
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-[#transparent] grid place-items-center">
     <iframe width="560" height="315" src="https://www.youtube.com/embed/hXJ4gZshNys?si=CQIbGMDM7rG_qWdx" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </div>
  );
};

export default WatchOurAds;