export const examples = {
  ent: {
    faq_list: [
      {
        question:
          "How does a care coordinator help a patient at Sharanya Care?",
        answer:
          "The care coordinator, on day 1 or during the first interaction tries to understand the patient’s problem and guides him/ her with the best treatment options. The care coordinator will assist you in getting your OPD scheduled and will help you connect with the best doctor according to your needs.",
      },
      {
        question:
          "Does Sharanya Care provide any emergency surgical treatment?",
        answer:
          "No, Sharanya Care does not attend to any kind of emergency surgeries or treatments. Sharanya Care only provides elective surgical care, i.e; surgeries that are scheduled in advance because it does not involve a medical emergency.",
      },
      {
        question: "Can I consult with a doctor online?",
        answer:
          "Yes, Sharanya Care offers online doctor consultations. With online consultation in place, patients can now consult a doctor based on their specialization from anywhere, anytime as per the available slots. Our patients can opt to talk to our doctors via call or online chat to discuss their health issues. Our doctors thoroughly understand the patient’s issue and provide them with the necessary medications and tests.",
      },
      {
        question:
          "Does Sharanya Care have insurance coverage for all surgeries?",
        answer:
          "Sharanya Care is not an insurance regulatory. Your insurance coverage depends on the health insurance type (personal, corporate) and the terms and conditions set by your insurance provider. Our insurance team only helps you get the maximum benefits of your policy.",
      },
      {
        question:
          "Does Sharanya Care provide a second opinion for any disease?",
        answer:
          "Yes, you can avail second medical opinion by specialized doctors for all diseases. Our doctor tracks your pathology reports, your post-operative report (if you had surgery earlier), your discharge summary (if you were hospitalized earlier for the disease), your current treatment and medication plan, and guides you with the required/ new treatment course ahead.",
      },
    ],
    section_1_data: {
      heading: "Comprehensive ENT Care Center",
      description:
        "At Sharanya Care, we are dedicated to providing comprehensive and compassionate care for ear, nose, and throat conditions. Our team of experienced ENT specialists utilizes state-of-the-art technology to diagnose and treat a wide range of ENT disorders. Whether you're dealing with hearing loss, sinus issues, or throat problems, we strive to deliver personalized and effective solutions to improve your overall well-being.",
    },
    specialities: [
      {
        title: "Allergy Testing and Immunotherapy",
        description:
          "Allergy testing involves identifying specific allergens causing allergic reactions. Immunotherapy, also known as allergy shots, aims to desensitize the immune system to these allergens, reducing allergic symptoms over time.",
      },
      {
        title: "Lobuloplasty",
        description:
          "Lobuloplasty is a surgical procedure to reshape or repair the earlobe. It is often performed to address stretched or torn earlobes caused by heavy earrings or other factors.",
      },
      {
        title: "Tympanoplasty",
        description:
          "Tympanoplasty is a surgical procedure to repair the eardrum (tympanic membrane). It may be performed to treat perforations, hearing loss, or chronic ear infections by reconstructing the damaged eardrum.",
      },
      {
        title: "Mastoidectomy",
        description:
          "Mastoidectomy is a surgical procedure to remove infected or damaged tissue in the mastoid bone, often performed to treat chronic ear infections that have spread to the mastoid area.",
      },
      {
        title: "Tonsillectomy",
        description:
          "Tonsillectomy is the surgical removal of the tonsils, often performed to treat recurrent tonsillitis, sleep apnea, or other conditions affecting the tonsils.",
      },
      {
        title: "Adenoidectomy",
        description:
          "Adenoidectomy is the surgical removal of the adenoids, which are glands located in the back of the throat. It is commonly done to address issues like recurrent infections or breathing difficulties.",
      },
      {
        title: "Snoring Surgery",
        description:
          "Snoring surgery includes various procedures aimed at addressing the underlying causes of snoring, such as nasal obstructions or palate issues, to improve breathing and reduce snoring.",
      },
      {
        title: "Septoplasty",
        description:
          "Septoplasty is a surgical procedure to correct a deviated septum, the cartilage and bone that divides the nasal cavity. It helps improve nasal airflow and may be done to address breathing difficulties or chronic sinus issues.",
      },
      {
        title: "Nasal Bone Fracture Reduction",
        description:
          "Nasal bone fracture reduction is a procedure to realign and stabilize fractured nasal bones. It aims to restore the normal structure of the nose after a traumatic injury.",
      },
      {
        title: "FESS (Functional Endoscopic Sinus Surgery)",
        description:
          "FESS is a surgical procedure using an endoscope to treat chronic sinusitis. It involves removing obstructions and improving drainage to alleviate symptoms and improve sinus function.",
      },
      {
        title: "Hearing Impairment",
        description:
          "Hearing impairment procedures include various interventions such as cochlear implants, hearing aids, or surgical procedures to address hearing loss and improve auditory function.",
      },
      {
        title: "Stapes Surgery",
        description:
          "Stapes surgery, also known as stapedectomy or stapedotomy, is a procedure to treat hearing loss caused by otosclerosis. It involves removing or modifying the stapes bone in the middle ear to improve sound transmission.",
      },
      {
        title: "Thyroid Surgery",
        description:
          "Thyroid surgery involves the removal of part or all of the thyroid gland. It may be performed to treat thyroid nodules, goiter, or thyroid cancer.",
      },
      {
        title: "Parotid Surgery",
        description:
          "Parotid surgery is the surgical removal of the parotid gland, often done to address tumors or infections affecting this major salivary gland.",
      },
      {
        title: "Vocal Cord Surgery",
        description:
          "Vocal cord surgery includes procedures such as vocal cord polyp removal, cordectomy, or other interventions to address issues affecting the vocal cords and improve voice function.",
      },
    ],
    section_2_data: {
      title: "ENT Treatment With Advanced Laser Treatment",
      description:
        "ENT (Ear, Nose, and Throat) treatment with advanced laser treatment involves using laser technology for various ENT procedures. This may include laser surgery for tonsillectomy, vocal cord surgery, and other precise interventions in the ear, nose, and throat.",
      list: [],
    },
  },
  dermatology: {
    faq_list: [
      {
        question:
          "How does a care coordinator help a patient at Sharanya Care?",
        answer:
          "The care coordinator, on day 1 or during the first interaction tries to understand the patient’s problem and guides him/ her with the best treatment options. The care coordinator will assist you in getting your OPD scheduled and will help you connect with the best doctor according to your needs.",
      },
      {
        question:
          "Does Sharanya Care provide any emergency surgical treatment?",
        answer:
          "No, Sharanya Care does not attend to any kind of emergency surgeries or treatments. Sharanya Care only provides elective surgical care, i.e; surgeries that are scheduled in advance because it does not involve a medical emergency.",
      },
      {
        question: "Can I consult with a doctor online?",
        answer:
          "Yes, Sharanya Care offers online doctor consultations. With online consultation in place, patients can now consult a doctor based on their specialization from anywhere, anytime as per the available slots. Our patients can opt to talk to our doctors via call or online chat to discuss their health issues. Our doctors thoroughly understand the patient’s issue and provide them with the necessary medications and tests.",
      },
      {
        question:
          "Does Sharanya Care have insurance coverage for all surgeries?",
        answer:
          "Sharanya Care is not an insurance regulatory. Your insurance coverage depends on the health insurance type (personal, corporate) and the terms and conditions set by your insurance provider. Our insurance team only helps you get the maximum benefits of your policy.",
      },
      {
        question:
          "Does Sharanya Care provide a second opinion for any disease?",
        answer:
          "Yes, you can avail second medical opinion by specialized doctors for all diseases. Our doctor tracks your pathology reports, your post-operative report (if you had surgery earlier), your discharge summary (if you were hospitalized earlier for the disease), your current treatment and medication plan, and guides you with the required/ new treatment course ahead.",
      },
    ],
    section_1_data: {
      heading: "Advanced Dermatology Solutions",
      description:
        "Sharanya Care is a leading dermatology center committed to providing advanced skincare solutions. Our team of skilled dermatologists specializes in diagnosing and treating a wide range of skin conditions, from common issues to complex dermatological disorders.Using cutting-edge technology and evidence-based practices, we offer personalized treatment plans to enhance the health and appearance of your skin. Trust us for expert care that prioritizes your skin's well-being and beauty.",
    },
    specialities: [
      {
        title: "Dermabrasion",
        description:
          "Dermabrasion is a cosmetic procedure that involves the removal of the top layer of skin, helping to reduce the appearance of scars, wrinkles, and other skin irregularities. It promotes skin regeneration for a smoother complexion.",
      },
      {
        title: "Fractional Laser",
        description:
          "Fractional laser therapy targets specific areas of the skin, creating microscopic wounds to stimulate collagen production. This minimizes fine lines, wrinkles, and improves skin texture, resulting in a more youthful appearance.",
      },
      {
        title: "CO2 Laser",
        description:
          "CO2 laser resurfacing is a technique that uses short pulses of light to remove damaged skin layers. It is effective for treating wrinkles, scars, and other skin imperfections. The procedure stimulates collagen production, promoting skin rejuvenation.",
      },
      {
        title: "Permanent Hair Reduction",
        description:
          "Permanent hair reduction involves the use of laser or other methods to reduce or remove unwanted hair. It provides long-lasting results by targeting hair follicles and inhibiting their growth.",
      },
      {
        title: "HydraFacial",
        description:
          "HydraFacial is a non-invasive skincare treatment that combines cleansing, exfoliation, extraction, hydration, and antioxidant protection. It rejuvenates and hydrates the skin, leaving it radiant and refreshed.",
      },
      {
        title: "PhotoFacial",
        description:
          "PhotoFacial, or Intense Pulsed Light (IPL) therapy, targets skin issues such as sun damage, pigmentation, and redness. It uses light energy to stimulate collagen production and improve overall skin tone and texture.",
      },
      {
        title: "Vitiligo",
        description:
          "Vitiligo is a skin condition characterized by the loss of pigmentation, leading to white patches on the skin. Treatment options may include topical medications, phototherapy, and surgical procedures to restore pigmentation.",
      },
      {
        title: "Tattoo Removal",
        description:
          "Tattoo removal involves the use of lasers to break down tattoo ink particles, allowing the body to naturally eliminate them. It is a gradual process that may require multiple sessions for optimal results.",
      },
      {
        title: "Ear Lobuloplasty",
        description:
          "Ear lobuloplasty is a cosmetic procedure to reshape or repair the earlobe. It is often performed to address stretched or torn earlobes caused by heavy earrings or other factors.",
      },
      {
        title: "Dermal Fillers",
        description:
          "Dermal fillers are injectable substances used to restore volume, smooth wrinkles, and enhance facial contours. Common fillers include hyaluronic acid, collagen, and other biocompatible materials.",
      },
      {
        title: "PRP (Platelet-Rich Plasma)",
        description:
          "PRP therapy involves using the patient's own platelet-rich plasma to stimulate natural healing processes. It is commonly used for facial rejuvenation, hair restoration, and to accelerate tissue healing.",
      },
      {
        title: "Warts/Corn/Skin Tag Removal",
        description:
          "Removal procedures for warts, corns, and skin tags involve various methods, including laser therapy, cryotherapy, and surgical excision, to eliminate these common skin growths.",
      },
      {
        title: "Vampire Facial",
        description:
          "The Vampire Facial, also known as PRP facial rejuvenation, combines microneedling with the application of platelet-rich plasma (PRP) to promote collagen production and improve skin texture and tone.",
      },
      {
        title: "TCA Peel",
        description:
          "TCA (Trichloroacetic Acid) peel is a chemical peel that exfoliates the skin, addressing issues like fine lines, wrinkles, and uneven pigmentation. It stimulates collagen production for a smoother complexion.",
      },
      {
        title: "Glyco-Peel",
        description:
          "Glyco-peel is a chemical peel that uses glycolic acid to exfoliate the skin, improving texture and promoting cell turnover. It is effective for treating hyperpigmentation, acne scars, and fine lines.",
      },
      {
        title: "Under Eye Peel",
        description:
          "Under-eye peels target the delicate skin under the eyes, addressing issues such as dark circles, fine lines, and puffiness. These peels aim to rejuvenate and brighten the under-eye area.",
      },
      {
        title: "TCA Cross",
        description:
          "TCA Cross is a specialized technique using Trichloroacetic Acid to treat atrophic acne scars. It involves precise application to stimulate collagen production and improve the appearance of scars.",
      },
      {
        title: "Stem Cell Therapy",
        description:
          "Stem cell therapy involves using stem cells to promote tissue regeneration and repair. In dermatology, it may be applied for skin rejuvenation and addressing specific skin concerns.",
      },
      {
        title: "Scar Marks Abrasion Surgical",
        description:
          "Surgical abrasion of scar marks involves removing damaged or scarred skin through a surgical procedure. It is performed to improve the appearance of scars, promoting smoother skin.",
      },
      {
        title: "Circumcision",
        description:
          "Circumcision is a surgical procedure involving the removal of the foreskin from the penis. It is often performed for cultural, religious, or medical reasons, and it may contribute to hygiene and health.",
      },
      {
        title: "Non-Surgical Liposuction",
        description:
          "Non-surgical liposuction techniques use various technologies, such as ultrasound or laser, to reduce localized fat deposits without the need for traditional surgical procedures. These methods aim to contour the body.",
      },
      {
        title: "Diabetic Foot Care",
        description:
          "Diabetic foot care involves specialized attention to the feet of individuals with diabetes to prevent complications like infections and ulcers. Regular monitoring, proper footwear, and treatment are essential for diabetic foot health.",
      },
      {
        title: "Subcision",
        description:
          "Subcision is a dermatological procedure that involves the use of a needle or blade to release fibrous bands beneath the skin's surface. It is commonly used to improve the appearance of acne scars and depressions in the skin.",
      },
    ],
    section_2_data: {
      title: "Dermatology",
      description:
        "Dermatology specializes in the diagnosis and treatment of skin, hair, and nail conditions. Services may include acne treatment, skin cancer screenings, cosmetic procedures, and management of various skin disorders.",
      list: [],
    },
  },
  medicine: {
    faq_list: [
      {
        question:
          "How does a care coordinator help a patient at Sharanya Care?",
        answer:
          "The care coordinator, on day 1 or during the first interaction tries to understand the patient’s problem and guides him/ her with the best treatment options. The care coordinator will assist you in getting your OPD scheduled and will help you connect with the best doctor according to your needs.",
      },
      {
        question:
          "Does Sharanya Care provide any emergency surgical treatment?",
        answer:
          "No, Sharanya Care does not attend to any kind of emergency surgeries or treatments. Sharanya Care only provides elective surgical care, i.e; surgeries that are scheduled in advance because it does not involve a medical emergency.",
      },
      {
        question: "Can I consult with a doctor online?",
        answer:
          "Yes, Sharanya Care offers online doctor consultations. With online consultation in place, patients can now consult a doctor based on their specialization from anywhere, anytime as per the available slots. Our patients can opt to talk to our doctors via call or online chat to discuss their health issues. Our doctors thoroughly understand the patient’s issue and provide them with the necessary medications and tests.",
      },
      {
        question:
          "Does Sharanya Care have insurance coverage for all surgeries?",
        answer:
          "Sharanya Care is not an insurance regulatory. Your insurance coverage depends on the health insurance type (personal, corporate) and the terms and conditions set by your insurance provider. Our insurance team only helps you get the maximum benefits of your policy.",
      },
      {
        question:
          "Does Sharanya Care provide a second opinion for any disease?",
        answer:
          "Yes, you can avail second medical opinion by specialized doctors for all diseases. Our doctor tracks your pathology reports, your post-operative report (if you had surgery earlier), your discharge summary (if you were hospitalized earlier for the disease), your current treatment and medication plan, and guides you with the required/ new treatment course ahead.",
      },
    ],
    section_1_data: {
      heading: "Holistic Internal Medicine Care",
      description:
        "Sharanya Care is a comprehensive healthcare center specializing in internal medicine. Our dedicated team of experienced physicians is committed to providing holistic and patient-centered care. We focus on the prevention, diagnosis, and treatment of a wide range of medical conditions. With state-of-the-art facilities and a compassionate approach, we strive to promote overall wellness and address the unique healthcare needs of each individual. Trust us for expert guidance and personalized medical care to optimize your health.",
    },
    specialities: [
      {
        title: "Diabetes Foot",
        description:
          "Diabetes foot refers to complications related to the feet in individuals with diabetes. It includes conditions such as neuropathy, ulcers, and infections. Proper management and foot care are crucial to prevent complications and maintain foot health.",
      },
      {
        title: "Diabetes Management",
        description:
          "Diabetes management involves a comprehensive approach to control blood sugar levels and prevent complications. It includes lifestyle modifications, medication, regular monitoring, and education on nutrition and exercise to optimize overall health.",
      },
      {
        title: "Diabetes Reversal Programme",
        description:
          "A diabetes reversal program focuses on lifestyle changes, including diet and exercise, to improve insulin sensitivity and lower blood sugar levels. The goal is to achieve better control and, in some cases, reverse the progression of diabetes.",
      },
      {
        title: "Blood Pressure Management",
        description:
          "Blood pressure management aims to control and maintain optimal blood pressure levels. It involves lifestyle modifications, such as a healthy diet, regular exercise, and medication when necessary, to prevent complications associated with high blood pressure.",
      },
    ],
    section_2_data: {
      title: "Internal Medicine",
      description:
        "Internal medicine focuses on the prevention, diagnosis, and treatment of adult diseases. Internal medicine specialists, also known as internists, provide comprehensive care for the entire body, from common illnesses to complex medical conditions.",
      list: [],
    },
  },
  respiratory: {
    faq_list: [
      {
        question:
          "How does a care coordinator help a patient at Sharanya Care?",
        answer:
          "The care coordinator, on day 1 or during the first interaction tries to understand the patient’s problem and guides him/ her with the best treatment options. The care coordinator will assist you in getting your OPD scheduled and will help you connect with the best doctor according to your needs.",
      },
      {
        question:
          "Does Sharanya Care provide any emergency surgical treatment?",
        answer:
          "No, Sharanya Care does not attend to any kind of emergency surgeries or treatments. Sharanya Care only provides elective surgical care, i.e; surgeries that are scheduled in advance because it does not involve a medical emergency.",
      },
      {
        question: "Can I consult with a doctor online?",
        answer:
          "Yes, Sharanya Care offers online doctor consultations. With online consultation in place, patients can now consult a doctor based on their specialization from anywhere, anytime as per the available slots. Our patients can opt to talk to our doctors via call or online chat to discuss their health issues. Our doctors thoroughly understand the patient’s issue and provide them with the necessary medications and tests.",
      },
      {
        question:
          "Does Sharanya Care have insurance coverage for all surgeries?",
        answer:
          "Sharanya Care is not an insurance regulatory. Your insurance coverage depends on the health insurance type (personal, corporate) and the terms and conditions set by your insurance provider. Our insurance team only helps you get the maximum benefits of your policy.",
      },
      {
        question:
          "Does Sharanya Care provide a second opinion for any disease?",
        answer:
          "Yes, you can avail second medical opinion by specialized doctors for all diseases. Our doctor tracks your pathology reports, your post-operative report (if you had surgery earlier), your discharge summary (if you were hospitalized earlier for the disease), your current treatment and medication plan, and guides you with the required/ new treatment course ahead.",
      },
    ],
    section_1_data: {
      heading: "Center for Respiratory Health Excellence",
      description:
        "Sharanya Care is a specialized center dedicated to providing comprehensive respiratory health services. Our team of skilled pulmonologists and respiratory therapists focuses on the diagnosis and treatment of conditions affecting the lungs and respiratory system. Whether you're dealing with asthma, chronic bronchitis, or other respiratory disorders, we offer state-of-the-art diagnostics and personalized treatment plans to help you breathe easier. At BreatheWell, we are committed to promoting lung health and improving the quality of life for individuals with respiratory challenges.",
    },
    specialities: [
      {
        title: "COPD (Chronic Obstructive Pulmonary Disease)",
        description:
          "COPD is a progressive lung disease that obstructs airflow, making it difficult to breathe. It includes conditions such as chronic bronchitis and emphysema. Treatment aims to manage symptoms and improve lung function.",
      },
      {
        title: "Pneumonia",
        description:
          "Pneumonia is an infection that inflames the air sacs in one or both lungs, causing cough, fever, and difficulty breathing. Treatment involves antibiotics, rest, and supportive care to help the lungs recover.",
      },
      {
        title: "Lung Cancer",
        description:
          "Lung cancer is the uncontrolled growth of abnormal cells in the lungs. It is often associated with smoking but can occur in non-smokers as well. Treatment options include surgery, chemotherapy, radiation, and targeted therapies.",
      },
      {
        title: "Interstitial Lung Disease",
        description:
          "Interstitial lung disease refers to a group of disorders that cause scarring and inflammation of lung tissue. Treatment aims to manage symptoms and address the underlying cause, which may include medications and oxygen therapy.",
      },
      {
        title: "Sarcoidosis",
        description:
          "Sarcoidosis is an inflammatory disease that can affect multiple organs, including the lungs. Treatment may involve medications to reduce inflammation and manage symptoms, but the condition may resolve on its own in some cases.",
      },
      {
        title: "Pulmonary Embolism",
        description:
          "Pulmonary embolism occurs when a blood clot travels to the lungs, blocking blood flow. It can be life-threatening and requires immediate medical attention. Treatment includes blood thinners and, in severe cases, interventions to remove or break up the clot.",
      },
      {
        title: "Tuberculosis",
        description:
          "Tuberculosis (TB) is a bacterial infection that primarily affects the lungs. Treatment involves a course of antibiotics to eliminate the bacteria. It is important to complete the prescribed treatment to prevent the spread of TB.",
      },
      {
        title: "Asthma & Allergy",
        description:
          "Asthma is a chronic respiratory condition characterized by inflammation and narrowing of the airways, leading to symptoms such as wheezing and shortness of breath. Allergies can trigger asthma symptoms. Treatment includes bronchodilators, anti-inflammatory medications, and allergy management.",
      },
      {
        title: "Pleural Diseases",
        description:
          "Pleural diseases involve conditions affecting the pleura, the thin membranes surrounding the lungs. Examples include pleurisy and pleural effusion. Treatment depends on the underlying cause and may involve medications or procedures to drain excess fluid.",
      },
      {
        title: "Chronic Respiratory Failures",
        description:
          "Chronic respiratory failure occurs when the lungs cannot adequately exchange oxygen and carbon dioxide. It is often associated with chronic lung diseases. Treatment may include oxygen therapy, medications, and management of the underlying condition.",
      },
    ],
    section_2_data: {
      title: "Respiratory",
      description:
        "Respiratory medicine focuses on the diagnosis and treatment of conditions affecting the lungs and respiratory system. Respiratory specialists, also known as pulmonologists, provide comprehensive care for a wide range of respiratory disorders.",

      list: [],
    },
  },
  orthopaedic: {
    faq_list: [
      {
        question:
          "How does a care coordinator help a patient at Sharanya Care?",
        answer:
          "The care coordinator, on day 1 or during the first interaction tries to understand the patient’s problem and guides him/ her with the best treatment options. The care coordinator will assist you in getting your OPD scheduled and will help you connect with the best doctor according to your needs.",
      },
      {
        question:
          "Does Sharanya Care provide any emergency surgical treatment?",
        answer:
          "No, Sharanya Care does not attend to any kind of emergency surgeries or treatments. Sharanya Care only provides elective surgical care, i.e; surgeries that are scheduled in advance because it does not involve a medical emergency.",
      },
      {
        question: "Can I consult with a doctor online?",
        answer:
          "Yes, Sharanya Care offers online doctor consultations. With online consultation in place, patients can now consult a doctor based on their specialization from anywhere, anytime as per the available slots. Our patients can opt to talk to our doctors via call or online chat to discuss their health issues. Our doctors thoroughly understand the patient’s issue and provide them with the necessary medications and tests.",
      },
      {
        question:
          "Does Sharanya Care have insurance coverage for all surgeries?",
        answer:
          "Sharanya Care is not an insurance regulatory. Your insurance coverage depends on the health insurance type (personal, corporate) and the terms and conditions set by your insurance provider. Our insurance team only helps you get the maximum benefits of your policy.",
      },
      {
        question:
          "Does Sharanya Care provide a second opinion for any disease?",
        answer:
          "Yes, you can avail second medical opinion by specialized doctors for all diseases. Our doctor tracks your pathology reports, your post-operative report (if you had surgery earlier), your discharge summary (if you were hospitalized earlier for the disease), your current treatment and medication plan, and guides you with the required/ new treatment course ahead.",
      },
    ],
    section_1_data: {
      heading: "Advanced Orthopedic Care Center",
      description:
        "Sharanya Care is a leading center for orthopedic care, dedicated to providing advanced and compassionate treatment for musculoskeletal conditions. Our team of orthopedic specialists includes surgeons, physical therapists, and rehabilitation experts who work collaboratively to diagnose and treat a wide range of orthopedic issues. From joint replacements to sports injuries, we leverage state-of-the-art technology and evidence-based practices to create personalized treatment plans that promote mobility and improve overall musculoskeletal health. Trust OrthoCare for excellence in orthopedic care, helping you regain your strength and live an active, pain-free life.",
    },
    specialities: [
      {
        title: "ACL Injuries",
        description:
          "ACL (Anterior Cruciate Ligament) injuries commonly occur during sports or activities involving sudden stops or changes in direction. Treatment may involve rehabilitation exercises or surgical intervention to repair or reconstruct the ACL.",
      },
      {
        title: "Ganglion Treatment",
        description:
          "Ganglion treatment involves addressing ganglion cysts, which are non-cancerous lumps filled with synovial fluid. Treatment options may include observation, aspiration (fluid removal), or surgical excision, depending on the size and symptoms.",
      },
      {
        title: "PRP Injection",
        description:
          "PRP (Platelet-Rich Plasma) injection is a regenerative therapy that uses the patient's own platelets to promote healing. It is commonly used in orthopedics to treat conditions such as tendon injuries, osteoarthritis, and ligament sprains.",
      },
      {
        title: "Intra-Articular Injection",
        description:
          "Intra-articular injection involves delivering medication directly into a joint space. This procedure is often used to alleviate pain and inflammation associated with conditions like osteoarthritis, rheumatoid arthritis, or joint injuries.",
      },
      {
        title: "Fracture Management",
        description:
          "Fracture management involves the assessment, stabilization, and treatment of bone fractures. It may include immobilization with casts or splints, surgical intervention, and rehabilitation to promote healing and restore function.",
      },
    ],
    section_2_data: {
      title: "Orthopedic",
      description:
        "Orthopedics focuses on the diagnosis and treatment of conditions affecting the musculoskeletal system. Orthopedic specialists, also known as orthopedists, provide comprehensive care for the bones, joints, muscles, ligaments, and tendons.",
      list: [],
    },
  },
  physiotherapy: {
    faq_list: [
      {
        question:
          "How does a care coordinator help a patient at Sharanya Care?",
        answer:
          "The care coordinator, on day 1 or during the first interaction tries to understand the patient’s problem and guides him/ her with the best treatment options. The care coordinator will assist you in getting your OPD scheduled and will help you connect with the best doctor according to your needs.",
      },
      {
        question:
          "Does Sharanya Care provide any emergency surgical treatment?",
        answer:
          "No, Sharanya Care does not attend to any kind of emergency surgeries or treatments. Sharanya Care only provides elective surgical care, i.e; surgeries that are scheduled in advance because it does not involve a medical emergency.",
      },
      {
        question: "Can I consult with a doctor online?",
        answer:
          "Yes, Sharanya Care offers online doctor consultations. With online consultation in place, patients can now consult a doctor based on their specialization from anywhere, anytime as per the available slots. Our patients can opt to talk to our doctors via call or online chat to discuss their health issues. Our doctors thoroughly understand the patient’s issue and provide them with the necessary medications and tests.",
      },
      {
        question:
          "Does Sharanya Care have insurance coverage for all surgeries?",
        answer:
          "Sharanya Care is not an insurance regulatory. Your insurance coverage depends on the health insurance type (personal, corporate) and the terms and conditions set by your insurance provider. Our insurance team only helps you get the maximum benefits of your policy.",
      },
      {
        question:
          "Does Sharanya Care provide a second opinion for any disease?",
        answer:
          "Yes, you can avail second medical opinion by specialized doctors for all diseases. Our doctor tracks your pathology reports, your post-operative report (if you had surgery earlier), your discharge summary (if you were hospitalized earlier for the disease), your current treatment and medication plan, and guides you with the required/ new treatment course ahead.",
      },
    ],
    section_1_data: {
      heading: "Rehabilitative Excellence: Physiotherapy Center",
      description:
        "Sharanya Care is a specialized center dedicated to rehabilitative excellence through physiotherapy. Our team of skilled physiotherapists focuses on restoring and enhancing the physical well-being of individuals dealing with injuries, pain, or mobility issues. Using evidence-based practices and personalized treatment plans, we address a wide range of musculoskeletal and neurological conditions. Whether you're recovering from surgery, managing chronic pain, or seeking sports rehabilitation, our goal is to help you regain strength, flexibility, and function. Trust Sharanya Care for comprehensive and compassionate care to empower you on your journey to optimal physical health.",
    },
    specialities: [
      {
        title: "Pain Management",
        description:
          "Pain management involves comprehensive approaches to address and alleviate chronic or acute pain. It may include medications, physical therapy, lifestyle modifications, and alternative therapies to improve the quality of life for individuals dealing with pain.",
      },
      {
        title: "Bell’s Palsy",
        description:
          "Bell's Palsy is a condition characterized by sudden, temporary weakness or paralysis of the facial muscles. Treatment may involve medications, physical therapy, and supportive measures to enhance facial muscle function and promote recovery.",
      },
      {
        title: "Facial Palsy",
        description:
          "Facial palsy refers to weakness or paralysis of the facial muscles, often caused by nerve damage. Rehabilitation may include exercises, electrical stimulation, and other therapeutic interventions to improve facial muscle strength and function.",
      },
      {
        title: "Gait Training",
        description:
          "Gait training is a rehabilitation process that focuses on improving a person's ability to walk. It involves exercises, balance training, and mobility drills to enhance gait patterns and restore walking function after injury or illness.",
      },
      {
        title: "Paralysis Management",
        description:
          "Paralysis management aims to address the challenges associated with paralysis, which is the loss of muscle function. Rehabilitation may include physical therapy, assistive devices, and adaptive strategies to enhance independence and quality of life.",
      },
      {
        title: "Geriatric Rehabilitation",
        description:
          "Geriatric rehabilitation is tailored for older adults to address age-related physical limitations and promote functional independence. It may include exercises, balance training, and interventions to enhance mobility and overall well-being.",
      },
      {
        title: "Pre/Post Operative Rehabilitation",
        description:
          "Pre/Post-operative rehabilitation involves preparing individuals for surgery through exercises and education before the procedure and facilitating recovery through targeted rehabilitation afterward. The goal is to optimize outcomes and restore function.",
      },
    ],
    section_2_data: {
      title: "Physiotherapy",
      description:
        "Physiotherapy focuses on restoring and maintaining optimal physical function. Physiotherapists, also known as physical therapists, provide comprehensive care for individuals dealing with injuries, pain, or mobility issues.",

      list: [],
    },
  },
  dental: {
    faq_list: [
      {
        question:
          "How does a care coordinator help a patient at Sharanya Care?",
        answer:
          "The care coordinator, on day 1 or during the first interaction tries to understand the patient’s problem and guides him/ her with the best treatment options. The care coordinator will assist you in getting your OPD scheduled and will help you connect with the best doctor according to your needs.",
      },
      {
        question:
          "Does Sharanya Care provide any emergency surgical treatment?",
        answer:
          "No, Sharanya Care does not attend to any kind of emergency surgeries or treatments. Sharanya Care only provides elective surgical care, i.e; surgeries that are scheduled in advance because it does not involve a medical emergency.",
      },
      {
        question: "Can I consult with a doctor online?",
        answer:
          "Yes, Sharanya Care offers online doctor consultations. With online consultation in place, patients can now consult a doctor based on their specialization from anywhere, anytime as per the available slots. Our patients can opt to talk to our doctors via call or online chat to discuss their health issues. Our doctors thoroughly understand the patient’s issue and provide them with the necessary medications and tests.",
      },
      {
        question:
          "Does Sharanya Care have insurance coverage for all surgeries?",
        answer:
          "Sharanya Care is not an insurance regulatory. Your insurance coverage depends on the health insurance type (personal, corporate) and the terms and conditions set by your insurance provider. Our insurance team only helps you get the maximum benefits of your policy.",
      },
      {
        question:
          "Does Sharanya Care provide a second opinion for any disease?",
        answer:
          "Yes, you can avail second medical opinion by specialized doctors for all diseases. Our doctor tracks your pathology reports, your post-operative report (if you had surgery earlier), your discharge summary (if you were hospitalized earlier for the disease), your current treatment and medication plan, and guides you with the required/ new treatment course ahead.",
      },
    ],
    section_1_data: {
      heading: "Sharanya Care: Your Partner in Oral Health",
      description:
        "Sharanya Care is a state-of-the-art dental care center committed to providing comprehensive and personalized oral health services. Our team of experienced dentists and dental hygienists is dedicated to helping you achieve and maintain a healthy, beautiful smile. From routine cleanings to advanced restorative procedures, we offer a wide range of dental services using the latest technology and techniques. Your comfort and satisfaction are our priorities, and we strive to create a welcoming environment for patients of all ages. Trust Sharanya Care for excellence in dental care and personalized treatments tailored to meet your unique oral health needs.",
    },
    specialities: [
      {
        title: "Scaling with Polishing",
        description:
          "Scaling with polishing is a dental cleaning procedure performed to remove plaque and tartar from the teeth, preventing gum disease and promoting oral health. Polishing helps smooth the tooth surfaces and enhance aesthetic appeal.",
      },
      {
        title: "Tooth Whitening",
        description:
          "Tooth whitening, also known as teeth bleaching, is a cosmetic dental procedure to lighten the color of the teeth. It can be done through in-office treatments or at-home kits to achieve a brighter and whiter smile.",
      },
      {
        title: "Tooth-Colored Filling",
        description:
          "Tooth-colored fillings, or composite fillings, are used to restore teeth damaged by decay or minor fractures. These fillings match the natural color of the teeth, providing a more aesthetically pleasing result than traditional amalgam fillings.",
      },
      {
        title: "Impaction & Extraction",
        description:
          "Impaction and extraction refer to the removal of impacted teeth, commonly wisdom teeth, to prevent dental issues such as pain, infection, or misalignment. Extraction involves removing a tooth from its socket.",
      },
      {
        title: "Orthodontic Treatment",
        description:
          "Orthodontic treatment involves the use of braces, aligners, or other devices to correct misaligned teeth and jaws. It aims to improve the function and appearance of the teeth, enhancing overall oral health.",
      },
      {
        title: "Denture",
        description:
          "Dentures are removable appliances used to replace missing teeth and surrounding tissues. They can be complete (full dentures) or partial, restoring functionality and aesthetics for individuals with tooth loss.",
      },
      {
        title: "Capping",
        description:
          "Capping, also known as dental crowns or caps, involves covering a damaged or weakened tooth with a custom-made restoration. Crowns protect the tooth, restore its strength, and improve its appearance.",
      },
      {
        title: "Root Canal Treatment",
        description:
          "Root canal treatment is a procedure to treat infected or inflamed pulp (innermost part of the tooth) by removing it, cleaning the root canal, and sealing it. This helps save a tooth from extraction.",
      },
      {
        title: "Implant",
        description:
          "Dental implants are surgically placed artificial tooth roots used to support crowns, bridges, or dentures. They provide a permanent solution for replacing missing teeth, restoring function and aesthetics.",
      },
    ],
    section_2_data: {
      title: "Dentistry",
      description:
        "Dentistry focuses on the diagnosis and treatment of conditions affecting the teeth, gums, and other structures of the mouth. Dentists provide comprehensive care for a wide range of oral health issues.",
      list: [],
    },
  },
  homeopathy: {
    faq_list: [
      {
        question:
          "How does a care coordinator help a patient at Sharanya Care?",
        answer:
          "The care coordinator, on day 1 or during the first interaction tries to understand the patient’s problem and guides him/ her with the best treatment options. The care coordinator will assist you in getting your OPD scheduled and will help you connect with the best doctor according to your needs.",
      },
      {
        question:
          "Does Sharanya Care provide any emergency surgical treatment?",
        answer:
          "No, Sharanya Care does not attend to any kind of emergency surgeries or treatments. Sharanya Care only provides elective surgical care, i.e; surgeries that are scheduled in advance because it does not involve a medical emergency.",
      },
      {
        question: "Can I consult with a doctor online?",
        answer:
          "Yes, Sharanya Care offers online doctor consultations. With online consultation in place, patients can now consult a doctor based on their specialization from anywhere, anytime as per the available slots. Our patients can opt to talk to our doctors via call or online chat to discuss their health issues. Our doctors thoroughly understand the patient’s issue and provide them with the necessary medications and tests.",
      },
      {
        question:
          "Does Sharanya Care have insurance coverage for all surgeries?",
        answer:
          "Sharanya Care is not an insurance regulatory. Your insurance coverage depends on the health insurance type (personal, corporate) and the terms and conditions set by your insurance provider. Our insurance team only helps you get the maximum benefits of your policy.",
      },
      {
        question:
          "Does Sharanya Care provide a second opinion for any disease?",
        answer:
          "Yes, you can avail second medical opinion by specialized doctors for all diseases. Our doctor tracks your pathology reports, your post-operative report (if you had surgery earlier), your discharge summary (if you were hospitalized earlier for the disease), your current treatment and medication plan, and guides you with the required/ new treatment course ahead.",
      },
    ],
    section_1_data: {
      heading: "Holistic Healing with Homeopathy",
      description:
        "Sharanya Care Center is dedicated to providing holistic healing through the principles of homeopathy. Our team of experienced homeopathic practitioners believes in addressing the root cause of health issues to promote overall well-being. Using natural remedies and individualized treatment plans, we aim to stimulate the body's inherent healing abilities. Whether you're seeking relief from chronic conditions, allergies, or emotional imbalances, our approach is centered on understanding your unique health profile. Trust Sharanya Care Center for personalized and gentle care, embracing the philosophy that true health involves harmony of the body, mind, and spirit.",
    },
    specialities: [
      {
        title: "Asthma",
        description:
          "Asthma is a chronic respiratory condition characterized by inflammation of the airways, leading to wheezing, shortness of breath, and chest tightness. Treatment may involve medications, inhalers, and lifestyle modifications to manage symptoms and prevent exacerbations.",
      },
      {
        title: "Allergies",
        description:
          "Allergies occur when the immune system reacts to substances (allergens) that are usually harmless. Common symptoms include sneezing, itching, and nasal congestion. Treatment may involve allergen avoidance, medications, and allergy immunotherapy.",
      },
      {
        title: "Sinusitis",
        description:
          "Sinusitis is the inflammation of the sinus cavities, causing symptoms such as nasal congestion, facial pain, and headache. Treatment may include decongestants, nasal irrigation, antibiotics for bacterial infections, and supportive measures.",
      },
      {
        title: "Nasal Polyps",
        description:
          "Nasal polyps are noncancerous growths that develop in the nasal passages or sinuses, leading to symptoms like nasal congestion and loss of smell. Treatment may involve medications or surgical intervention, depending on the severity.",
      },
      {
        title: "Gastric Troubles",
        description:
          "Gastric troubles refer to digestive issues, including indigestion, acid reflux, and gastritis. Management may involve dietary changes, medications to reduce acidity, and lifestyle modifications to promote digestive health.",
      },
      {
        title: "Migraine",
        description:
          "Migraine is a neurological disorder characterized by severe headaches, often accompanied by nausea, sensitivity to light, and visual disturbances. Treatment may include medications to alleviate symptoms and lifestyle adjustments to prevent triggers.",
      },
      {
        title: "Anxiety",
        description:
          "Anxiety is a mental health condition characterized by excessive worry and fear. Treatment options include therapy, medications, and lifestyle changes to manage symptoms and improve overall well-being.",
      },
      {
        title: "Depression",
        description:
          "Depression is a mood disorder that affects a person's thoughts, feelings, and daily functioning. Treatment may involve therapy, medications, and support to address underlying causes and promote mental health.",
      },
      {
        title: "Menstrual Problems",
        description:
          "Menstrual problems include a range of issues affecting the menstrual cycle, such as irregular periods, heavy bleeding, or painful menstruation. Treatment may involve hormonal therapies, lifestyle changes, and addressing underlying health conditions.",
      },
      {
        title: "Arthritis",
        description:
          "Arthritis is the inflammation of joints, causing pain and stiffness. There are various types, including osteoarthritis and rheumatoid arthritis. Treatment may include medications, physical therapy, and lifestyle modifications to manage symptoms and improve joint function.",
      },
      {
        title: "Spondylosis",
        description:
          "Spondylosis is a degenerative condition affecting the spine, leading to pain and stiffness. Treatment may involve medications, physical therapy, and lifestyle modifications to manage symptoms and maintain spinal health.",
      },
      {
        title: "Skin Disorders",
        description:
          "Skin disorders encompass a wide range of conditions, including eczema, psoriasis, and dermatitis. Treatment varies based on the specific disorder and may involve topical medications, phototherapy, or systemic treatments.",
      },
      {
        title: "Children Disorders",
        description:
          "Children disorders cover a broad spectrum of health conditions affecting children, including developmental, behavioral, and medical issues. Treatment is tailored to the specific disorder and may involve medical interventions, therapy, and support for the child and their family.",
      },
    ],
    section_2_data: {
      title: "Homeopathy",
      description:
        "Homeopathy is a holistic system of medicine that uses natural substances to stimulate the body's inherent healing abilities. Homeopathic remedies are highly individualized and tailored to each person's unique health profile.",
      list: [],
    },
  },
  diet: {
    faq_list: [
      {
        question:
          "How does a care coordinator help a patient at Sharanya Care?",
        answer:
          "The care coordinator, on day 1 or during the first interaction tries to understand the patient’s problem and guides him/ her with the best treatment options. The care coordinator will assist you in getting your OPD scheduled and will help you connect with the best doctor according to your needs.",
      },
      {
        question:
          "Does Sharanya Care provide any emergency surgical treatment?",
        answer:
          "No, Sharanya Care does not attend to any kind of emergency surgeries or treatments. Sharanya Care only provides elective surgical care, i.e; surgeries that are scheduled in advance because it does not involve a medical emergency.",
      },
      {
        question: "Can I consult with a doctor online?",
        answer:
          "Yes, Sharanya Care offers online doctor consultations. With online consultation in place, patients can now consult a doctor based on their specialization from anywhere, anytime as per the available slots. Our patients can opt to talk to our doctors via call or online chat to discuss their health issues. Our doctors thoroughly understand the patient’s issue and provide them with the necessary medications and tests.",
      },
      {
        question:
          "Does Sharanya Care have insurance coverage for all surgeries?",
        answer:
          "Sharanya Care is not an insurance regulatory. Your insurance coverage depends on the health insurance type (personal, corporate) and the terms and conditions set by your insurance provider. Our insurance team only helps you get the maximum benefits of your policy.",
      },
      {
        question:
          "Does Sharanya Care provide a second opinion for any disease?",
        answer:
          "Yes, you can avail second medical opinion by specialized doctors for all diseases. Our doctor tracks your pathology reports, your post-operative report (if you had surgery earlier), your discharge summary (if you were hospitalized earlier for the disease), your current treatment and medication plan, and guides you with the required/ new treatment course ahead.",
      },
    ],
    section_1_data: {
      heading: "Sharanya Care: Your Path to Healthy Living",
      description:
        "Sharanya Care is a premier center dedicated to promoting healthy living through expert guidance in diet and nutrition. Our team of certified nutritionists and dietitians focuses on personalized and evidence-based approaches to help you achieve your health and wellness goals. Whether you're looking to manage weight, improve energy levels, or address specific dietary concerns, we provide tailored nutrition plans and ongoing support. At Sharanya Care, we believe in the power of nourishing your body with the right foods to enhance overall well-being. Start your journey to a healthier lifestyle with our dedicated team committed to your nutritional success.",
    },
    specialities: [
      {
        title: "Weight Loss Therapy",
        description:
          "Weight loss therapy involves personalized interventions to help individuals achieve and maintain a healthy weight. It may include a combination of dietary changes, exercise plans, behavioral modifications, and, in some cases, medical treatments to support weight loss goals.",
      },
      {
        title: "Weight Gain Therapy",
        description:
          "Weight gain therapy focuses on healthy strategies to help individuals gain weight in a controlled and sustainable manner. It typically involves dietary adjustments, nutritional counseling, and sometimes, targeted exercise regimens to promote muscle mass and overall well-being.",
      },
    ],
    section_2_data: {
      title: "Dietetics",
      description:
        "Dietetics focuses on the science of nutrition and its application to health and disease. Dietitians provide comprehensive care for individuals with specific dietary needs, including weight management, food allergies, and chronic conditions.",
      list: [],
    },
  },
  diagnostics: {
    faq_list: [
      {
        question:
          "How does a care coordinator help a patient at Sharanya Care?",
        answer:
          "The care coordinator, on day 1 or during the first interaction tries to understand the patient’s problem and guides him/ her with the best treatment options. The care coordinator will assist you in getting your OPD scheduled and will help you connect with the best doctor according to your needs.",
      },
      {
        question:
          "Does Sharanya Care provide any emergency surgical treatment?",
        answer:
          "No, Sharanya Care does not attend to any kind of emergency surgeries or treatments. Sharanya Care only provides elective surgical care, i.e; surgeries that are scheduled in advance because it does not involve a medical emergency.",
      },
      {
        question: "Can I consult with a doctor online?",
        answer:
          "Yes, Sharanya Care offers online doctor consultations. With online consultation in place, patients can now consult a doctor based on their specialization from anywhere, anytime as per the available slots. Our patients can opt to talk to our doctors via call or online chat to discuss their health issues. Our doctors thoroughly understand the patient’s issue and provide them with the necessary medications and tests.",
      },
      {
        question:
          "Does Sharanya Care have insurance coverage for all surgeries?",
        answer:
          "Sharanya Care is not an insurance regulatory. Your insurance coverage depends on the health insurance type (personal, corporate) and the terms and conditions set by your insurance provider. Our insurance team only helps you get the maximum benefits of your policy.",
      },
      {
        question:
          "Does Sharanya Care provide a second opinion for any disease?",
        answer:
          "Yes, you can avail second medical opinion by specialized doctors for all diseases. Our doctor tracks your pathology reports, your post-operative report (if you had surgery earlier), your discharge summary (if you were hospitalized earlier for the disease), your current treatment and medication plan, and guides you with the required/ new treatment course ahead.",
      },
    ],
    section_1_data: {
      heading: "Precision Diagnostics: Accurate and Timely Results",
      description:
        "Sharanya Care is a leading center for precision diagnostics, committed to delivering accurate and timely results. Equipped with state-of-the-art technology and a team of skilled technicians, we offer a comprehensive range of diagnostic services. From routine blood tests to advanced imaging, our focus is on providing precise information to support your healthcare needs. At Sharanya Care, we understand the importance of reliable diagnostics in medical decision-making. Trust us for excellence in diagnostic services, where accuracy and efficiency meet to ensure the best possible healthcare outcomes.",
    },
    specialities: [
      {
        title: "Gynaecology",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas, voluptatum.",
      },
      {
        title: "Laparoscopy",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas, voluptatum.",
      },
      {
        title: "Proctology",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas, voluptatum.",
      },
    ],
    section_2_data: {
      title: "Diagnostics",
      description:
        "Diagnostics involves the use of tests and procedures to identify and monitor health conditions. Diagnostic services include laboratory tests, imaging, and other interventions to provide information that supports medical decision-making.",
      list: [],
    },
  },
};
