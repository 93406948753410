export   const sub_menu_buttons = [
    {
      title: "DERMATOLOGY",
    },
    {
      title: "ENT",
    },
    {
      title: "MEDICINE",
    },
    {
      title: "RESPIRATORY",
    },
    {
      title: "ORTHOPAEDIC",
    },
    {
      title: "PHYSIOTHERAPY",
    },
    {
      title: "DENTAL",
    },
    {
      title: "HOMEOPATHY",
    },
    {
      title: "DIET",
    },
    {
      title: "DIAGNOSTICS",
    },
  ];