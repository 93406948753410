import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import Doctors from "./pages/doctors";
import ResponsiveDrawer from "./components/drawer";
import Footer from "./components/footer";
import About from "./pages/about";
import TermsConditions from "./pages/terms-conditions";
import PrivacyPolicy from "./pages/privacy-policy";
import RefundPolicy from "./pages/refund-policy";
import BlogPage from "./pages/blog"; // Updated import
import Treatments from "./pages/treatments";
import SpecialityDetails from "./components/others/SpecialityDetails";
import BookAppointment from "./pages/book-appointment";
import SplashScreen from "./pages/splash-screen";
import PostPage from "./pages/PostPage"; // Ensure this file exists
import IndexPage from "./pages/IndexPage"; // Ensure this file exists
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import CreatePost from "./pages/CreatePost";
import EditPost from "./pages/EditPost";
import { UserContextProvider } from './UserContext';



const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <>
      {loading ? (
        <SplashScreen />
      ) : (
        <div className="max-w-[1920px] mx-auto">
          <BrowserRouter>
            <ResponsiveDrawer />
            <UserContextProvider>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/doctors" element={<Doctors />} />
                <Route path="/about" element={<About />} />
                <Route path="/terms-and-condition" element={<TermsConditions />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/refund-policy" element={<RefundPolicy />} />
                <Route path="/blog" element={<BlogPage />} /> {/* Updated route */}
                <Route path="/post/:id" element={<PostPage />} />
                <Route path="/treatment/:treatmentId" element={<Treatments />} />
                <Route path="/treatment/:treatmentId/specialities/:specialityId" element={<SpecialityDetails />} />
                <Route path="/book-appointment" element={<BookAppointment />} />
                <Route path="/userlogin" element={<IndexPage />} />
                <Route path="/userlogin/login" element={<LoginPage />} />
                <Route path="/userlogin/register" element={<RegisterPage />} />
                <Route path="/userlogin/create" element={<CreatePost />} />
                <Route path="/userlogin/post/:id" element={<PostPage />} />
                <Route path="/userlogin/edit/:id" element={<EditPost />} />
              </Routes>
            </UserContextProvider>
            <Footer />
          </BrowserRouter>
        </div>
      )}
    </>
  );
};

export default App;