import React, { useEffect } from "react";

const RefundPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

 
  return (
    <>
    <main class="container mx-auto py-8 px-6 bg-white shadow-lg rounded-lg">
    
    <h2 class="text-4xl font-semibold mb-6">Refund & Cancellation Policy</h2>
    <section class="mb-10">
      <h2 class="text-2xl font-semibold mb-6">Returns</h2>
      <p class="text-lg leading-relaxed text-gray-700 mb-2">
      Our policy lasts 30 days. If 30 days have gone by since your purchase, unfortunately we can’t offer you a refund or exchange.
      </p>
      <p class="text-lg leading-relaxed text-gray-700 mb-2">
      To be eligible for a return, your item must be unused and in the same condition that you received it. It must also be in the original packaging.
      </p>
      <p class="text-lg leading-relaxed text-gray-700 mb-2">
      Several types of goods are exempt from being returned. Perishable goods such as food, flowers, newspapers or magazines cannot be returned. We also do not accept products that are intimate or sanitary goods, hazardous materials, or flammable liquids or gases.
      </p>
      <p class="text-lg leading-relaxed text-gray-700">
      Additional non-returnable items:
      </p>
      <ul class="list-disc pl-6 space-y-3">
        <li><strong>Provision of Services:</strong> We provide services as described on our website.</li>
        <li><strong>Rights and Responsibilities:</strong> Users are responsible for maintaining the confidentiality of their account.</li>
        <li><strong>Termination:</strong> We reserve the right to terminate access to our services at any time, for any reason.</li>
      </ul>

      <p class="text-lg leading-relaxed text-gray-700 mb-2 mt-4">
      To complete your return, we require a receipt or proof of purchase.
      </p>
      <p class="text-lg leading-relaxed text-gray-700 mb-2">
      Please do not send your purchase back to the manufacturer.
      </p>
      <p class="text-lg leading-relaxed text-gray-700 mb-4">
      There are certain situations where only partial refunds are granted: (if applicable)
      Book with obvious signs of use medical equipments.
      Any item not in its original condition, is damaged or missing parts for reasons not due to our error.
      Any item that is returned more than 30 days after delivery
      </p>
    </section>

    <section class="mb-10">
      <h2 class="text-2xl font-semibold mb-4">Refunds (if applicable)</h2>
      <p class="text-lg leading-relaxed text-gray-700 mb-4">
      Once your return is received and inspected, we will send you an email to notify you that we have received your returned item. We will also notify you of the approval or rejection of your refund.
      If you are approved, then your refund will be processed, and a credit will automatically be applied to your credit card or original method of payment, within a certain amount of days. 
      </p>
      <p class="text-lg leading-relaxed text-gray-700 mb-4">
      Late or missing refunds (if applicable)
      If you haven’t received a refund yet, first check your bank account again.
      Then contact your credit card company, it may take some time before your refund is officially posted.
      Next contact your bank. There is often some processing time before a refund is posted.
      If you’ve done all of this and you still have not received your refund yet, please contact us at Sharanya.clinic@gmail.com
      </p>
    </section>
    <section class="mb-10">
      <h2 class="text-2xl font-semibold mb-4">Sale items (if applicable)</h2>
      <p class="text-lg leading-relaxed text-gray-700 mb-4">
      Only regular priced items may be refunded, unfortunately sale items cannot be refunded.
      </p>
    </section>
    <section class="mb-10">
      <h2 class="text-2xl font-semibold mb-4">Exchanges (if applicable)</h2>
      <p class="text-lg leading-relaxed text-gray-700 mb-4">
      We only replace items if they are defective or damaged.  If you need to exchange it for the same item, send us an email at Sharanya.clinic@gmail.com and send your item to T-5, Jagran Chowk, Shukhar Bazar Road, Uttam Nagar West Metro – 110059
      </p>
    </section>
    <section class="mb-10">
      <h2 class="text-2xl font-semibold mb-4">Gifts</h2>
      <p class="text-lg leading-relaxed text-gray-700 mb-4">
      If the item was marked as a gift when purchased and shipped directly to you, you’ll receive a gift credit for the value of your return. Once the returned item is received, a gift certificate will be mailed to you.
      </p>
      <p class="text-lg leading-relaxed text-gray-700 mb-4">
      If the item wasn’t marked as a gift when purchased, or the gift giver had the order shipped to themselves to give to you later, we will send a refund to the gift giver and he will find out about your return.
      </p>
    </section>
    <section class="mb-10">
      <h2 class="text-2xl font-semibold mb-4">Shipping</h2>
      <p class="text-lg leading-relaxed text-gray-700 mb-4">
      To return your product, you should mail your product to: T-5, Jagran Chowk, Shukhar Bazar Road, Uttam Nagar West Metro – 110059
      </p>
      <p class="text-lg leading-relaxed text-gray-700 mb-4">
      You will be responsible for paying for your own shipping costs for returning your item. Shipping costs are non-refundable. If you receive a refund, the cost of return shipping will be deducted from your refund.
      </p>
      <p class="text-lg leading-relaxed text-gray-700 mb-4">
      Depending on where you live, the time it may take for your exchanged product to reach you, may vary.
      </p>
      <p class="text-lg leading-relaxed text-gray-700 mb-4">
      If you are shipping an item over Rs 400, you should consider using a trackable shipping service or purchasing shipping insurance. We don’t guarantee that we will receive your returned item.
      </p>
    </section>
  </main>
    </>
  );
};

export default RefundPolicy;