import React from "react";
import { Link } from "react-router-dom";
import { sub_menu_buttons } from "../../constants/menu";
import { Facebook, Instagram, Twitter, LocationOn } from "@mui/icons-material";


const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="flex flex-col gap-4 p-10 bg-[#2C6975] text-white">
      <div className="">
        <h2 className="text-2xl md:text-4xl font-bold gradient_text">
          <Link to={"/"} className="">
            Sharanya Care
          </Link>
        </h2>
      </div>

      <div className="grid grid-cols-2 md:grid-cols-4 gap-8 p-0 md:p-8">
        <div className="flex flex-col gap-2">
          <h4 className="text-xl font-bold">Our Company</h4>
          <Link to={"/"} className="text-gray-200 uppercase"> 
            Home
          </Link>
          <Link to={"/doctors"} className="text-gray-200 uppercase">
            Doctors
          </Link>
          <Link to={"/about"} className="text-gray-200 uppercase">
            About
          </Link>
          <Link to={"/blog"} className="text-gray-200 uppercase">
            Blog
          </Link>
        </div>

        <div className="flex flex-col gap-2">
          <h4 className="text-xl font-bold">Treatments</h4>
          {sub_menu_buttons.map((button, index) => (
            <Link
              to={"/treatment/" + button.title.toLowerCase()}
              className="text-gray-200" 
              key={index}
            >
              {button.title}
            </Link>
          ))}
        </div>
        <div className="flex flex-col gap-2">
          <h4 className="text-xl font-bold">For Patients</h4>
          <Link to={"/book-appointment"} className="text-gray-200">
            BOOK APPOINTMENT
          </Link>
        </div>

        <div className="flex flex-col gap-2">
          <h4 className="text-xl font-bold">Social Media</h4>
          <div className="flex gap-2">
            <Link
              to={"https://www.facebook.com/sharanya.polyclinic?mibextid=ZbWKwL"}
              className="text-gray-200"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="w-10 h-10 items-center justify-center bg-white rounded-full text-black flex">
                <Facebook fontSize="large" />
              </div>
            </Link>

            <Link
              to={"https://twitter.com"}
              className="text-gray-200"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="w-10 h-10 items-center justify-center bg-white rounded-full text-black flex">
                <Twitter fontSize="large" />
              </div>
            </Link>

            <Link
              to={"https://www.instagram.com/sharanya_healthcare?igsh=djBvdTF4ZDNoY2M3"}
              className="text-gray-200"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="w-10 h-10 items-center justify-center bg-white rounded-full text-black flex">
                <Instagram fontSize="large" />
              </div>
            </Link>
            <Link
              to={"https://www.google.com/maps/place/Sharanya+Health+Care/@28.6173124,57.542236,5z/data=!4m10!1m2!2m1!1ssharanya+Health+care!3m6!1s0x390d053ff6355255:0x6ec1b4fb7abaf8a0!8m2!3d28.6173124!4d77.0539548!15sCg1zaGFyYW55YSBjYXJlWg8iDXNoYXJhbnlhIGNhcmWSAQ1kZW50YWxfY2xpbmljmgEkQ2hkRFNVaE5NRzluUzBWSlEwRm5TVU5NYjNKMk1uZEJSUkFC4AEA!16s%2Fg%2F11f9zz4f_m?entry=ttu&g_ep=EgoyMDI0MDkwOC4wIKXMDSoASAFQAw%3D%3D"}
              className="text-gray-200"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="w-10 h-10 items-center justify-center bg-white rounded-full text-black flex">
                <LocationOn fontSize="large" />
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 md:flex-row text-xs">
        <div className="">
          &copy; Copyright Sharanya {currentYear}. All Right Reserved.
        </div>
        <div className="text-right">      
        <Link to={"/terms-and-condition"} className="text-gray-200 uppercase">
            Terms and Conditions
          </Link>
        <span className="mr-4 ml-4">|</span>  
        <Link to={"/privacy-policy"} className="text-gray-200 uppercase">
            Privacy Policy
          </Link>
        <span className="mr-4 ml-4">|</span>   
        <Link to={"/refund-policy"} className="text-gray-200 uppercase">
            Refund Policy
          </Link>       
        </div>    
      </div>
    </div>
  );
};


export default Footer;
